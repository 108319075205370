import { init } from "commandbar";
import { CommandBarClientAPI } from "commandbar/build/commandbar";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useOurRouter } from "../hooks/useOurRouter";
import { usePromise } from "../hooks/usePromise";
import { reclaim } from "../reclaim-api";
import { Browser } from "../utils/platform";
import { useUserContext } from "./UserContext";

export type CommandBarContextState = {
  CommandBar?: CommandBarClientAPI;
  status: string;
};

export type CommandBarContextValue = {
  state: CommandBarContextState;
};

export const defaultState: CommandBarContextState = {
  get CommandBar() {
    return Browser.isBrowser() ? window?.CommandBar : undefined;
  },
  status: "init",
};

export const CommandBarContext = createContext<CommandBarContextValue>({
  state: defaultState,
});

export function useCommandBarContext() {
  return useContext<CommandBarContextValue>(CommandBarContext);
}

export const CommandBarContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<CommandBarContextState>(defaultState);

  const router = useOurRouter();

  const [userState] = useUserContext();

  const { data: primaryCalendar } = usePromise(reclaim.calendars.getPrimary, []);

  const isBrowser = useMemo(() => Browser.isBrowser(), []);

  useEffect(() => {
    if (!isBrowser || "init" !== state.status) return;
    console.log("[CommandBar] init");

    // Init CommandBar
    init("84721cdb");
  }, [isBrowser, state.status]);

  useEffect(() => {
    if ("init" !== state.status) return;

    if (!isBrowser) {
      console.warn("[CommandBar] Abort boot, no window");
      return;
    }
    if (!window.CommandBar) {
      console.warn("[CommandBar] Abort boot, no window.CommandBar");
      return;
    }
    if (!userState.user?.trackingCode) {
      console.warn("[CommandBar] Abort boot, no user id");
      return;
    }
    if (!userState.user?.onboarded) {
      console.warn("[CommandBar] Abort boot, user not onboarded");
      return;
    }

    window.CommandBar.boot({
      id: userState.user.trackingCode,
    });

    setState((prev) => {
      prev.status = "boot";
      return prev;
    });
  }, [state.status, userState.user?.onboarded, userState.user?.trackingCode, isBrowser]);

  /* Handle status changes */
  useEffect(() => {
    if (!state.CommandBar) {
      console.log("[CommandBar] not loaded");
      return;
    }

    switch (state.status) {
      case "boot":
        console.log("[CommandBar] boot");
        // Set default context
        state.CommandBar.setContext({
          user: userState.user,
        });
        setState((prev) => {
          prev.status = "context";
          return prev;
        });
        break;
      case "context":
        console.log("[CommandBar] context");
        break;
      case "ok":
        console.log("[CommandBar] ok");
        break;
    }
  }, [state.CommandBar, state.status, userState.user]);

  useEffect(() => {
    // Load context when everything is available
    if (
      "context" !== state.status ||
      !router.isReady ||
      !userState.isAuthenticated ||
      !userState.user?.onboarded ||
      !primaryCalendar ||
      !!process.env.TEST_ENV
    )
      return;

    // Register router
    state.CommandBar?.addRouter((url: string) => router.push(url));
    setState((prev) => {
      prev.status = "ok";
      return prev;
    });

    // Set route context
    state.CommandBar?.addContext(
      {
        pages: ["planner", "stats", "tasks", "habits", "settings"],
        route: {
          route: router.route,
          pathname: router.pathname,
          asPath: router.asPath,
          params: router.params,
          query: router.query,
        },
      },
      undefined,
      undefined
    );

    import("../commandbar/tasks")
      .then((m) => m.init(state, userState.user, primaryCalendar))
      .catch((e) => console.error("Failed to load CommandBar tasks", e));
    import("../commandbar/habits")
      .then((m) => m.init(state, userState.user, primaryCalendar))
      .catch((e) => console.error("Failed to load CommandBar habits", e));
    import("../commandbar/help")
      .then((m) => m.init(state, userState.user, primaryCalendar))
      .catch((e) => console.error("Failed to load CommandBar help", e));
  }, [primaryCalendar, router, state, userState.isAuthenticated, userState.user]);

  return <CommandBarContext.Provider value={{ state }}>{children}</CommandBarContext.Provider>;
};

export default CommandBarContext;
