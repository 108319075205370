import { useCallback, useEffect, useReducer, useRef } from "react";
// TODO (ma) this is a leaky abstraction. Needs a better error type
// eslint-disable-next-line no-restricted-imports
import { NetworkError, ReclaimApiError } from "../reclaim-api/client";
import { isReturnFormatArray, ReturnFormatArray } from "../utils/promise";

// TODO (IW): Refactor this whole thing

function resolvePromise<Result>(promise, args?: any[]): Promise<Result> {
  if (typeof promise === "function") {
    return promise(...(args || []));
  }

  return promise;
}

export enum PromiseState {
  NotRun = "NOT_RUN",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Resolved = "RESOLVED",
}

const defaultState = {
  error: undefined,
  result: undefined,
  state: PromiseState.NotRun,
};

function reducer(previousState: any, action: { type: PromiseState; payload?: any }) {
  switch (action.type) {
    case PromiseState.Pending:
      return {
        ...defaultState,
        result: previousState.result,
        state: PromiseState.Pending,
      };

    case PromiseState.Resolved:
      return {
        error: undefined,
        result: action.payload,
        state: PromiseState.Resolved,
      };

    case PromiseState.Rejected:
      return {
        error: action.payload,
        result: undefined,
        state: PromiseState.Rejected,
      };

    default:
      return previousState;
  }
}

/**
 * Hook that handles all the async details of a promise
 *
 * @param promise async function
 * @param inputs arguments
 * @returns context object
 */
export function usePromise<
  Result,
  ReturnedErrorType = Error | ReclaimApiError<{ status: number }> | NetworkError,
  OptionalArgs extends unknown[] = unknown[]
>(
  promise: Promise<Result> | ((...args: OptionalArgs) => Promise<Result>),
  inputs?: OptionalArgs
): {
  data?: Result extends ReturnFormatArray<unknown, unknown> ? Result[0] : Result;
  error?: Result extends ReturnFormatArray<unknown, unknown> ? Result[1] : ReturnedErrorType;
  state: PromiseState;
  loading: boolean;
  load: (...args: OptionalArgs) => Promise<Result>;
  reset: () => void;
} {
  const [{ error, result: data, state }, dispatch] = useReducer(reducer, defaultState);

  const canceledRef = useRef<boolean>(false);

  const load = useCallback(
    (...args: OptionalArgs): Promise<Result> => {
      return new Promise((resolve, reject) => {
        const resolvedPromise = resolvePromise<Result>(promise, args);

        if (!resolvedPromise) {
          return;
        }

        dispatch({ type: PromiseState.Pending });

        resolvedPromise.then(
          (result) => {
            if (!!canceledRef.current) return;
            // Manage a return formatted array
            if (isReturnFormatArray(result)) {
              if (!!result[0]) {
                dispatch({
                  payload: result[0],
                  type: PromiseState.Resolved,
                });
                resolve(result[0] as Result);
              } else {
                dispatch({
                  payload: result[1],
                  type: PromiseState.Rejected,
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                reject(result[1] as any);
              }
              return;
            }

            // This is for everythign else
            dispatch({
              payload: result,
              type: PromiseState.Resolved,
            });
            resolve(result);
          },
          (error) => {
            if (!!canceledRef.current) return;
            dispatch({
              payload: error,
              type: PromiseState.Rejected,
            });
            reject(error);
          }
        );
      });
    },
    [promise]
  );

  useEffect(() => {
    canceledRef.current = false;

    // TODO (ma) expose this correctly. Right now we wont catch an error in TS
    // if the `promise` is a function, that requires args AND we have `inputs`
    if (typeof inputs !== "undefined") (load as any)(...inputs);

    return () => {
      canceledRef.current = true;
    };
  }, inputs);

  return {
    data,
    error,
    state,
    get loading() {
      return [PromiseState.NotRun, PromiseState.Pending].includes(state);
    },
    load,
    reset: () =>
      dispatch({
        payload: null,
        type: PromiseState.NotRun,
      }),
  };
}
